NProgress.configure({
    easing: 'ease',
    speed: 500,
    trickle: true,
    minimum: 0.4,
    showSpinner: false
});

$.fn.loadingOverlay.defaults = {
    loadingClass: 'loading',          /*Class added to target while loading*/
    overlayClass: 'loading-overlay',  /*Class added to overlay (style with CSS)*/
    spinnerClass: 'loading-spinner',  /*Class added to loading overlay spinner*/
    iconClass: 'loading-icon fa fa-fw fa-spinner fa-spin fa-3x',        /*Class added to loading overlay spinner*/
    textClass: 'hide',                /*Class added to loading overlay spinner*/
    loadingText: 'loading...'         /*Text within loading overlay*/
};

var niceScrollOptions = {
    cursorwidth: '6px',
    cursorcolor: "#747f8c",
    cursoropacitymax: 0.5,
    zindex: 1200
};

$(function ()
{
    NProgress.start();

    $.ajaxSetup({
        headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') }
    });

    /*Przechwycenie błędu związanego z wygaśnięciem tokena csrf*/
    (function()
    {
        var send = XMLHttpRequest.prototype.send;
        XMLHttpRequest.prototype.send = function()
        {
            this.onreadystatechange = function()
            {
                if (this.readyState == 4 && this.getResponseHeader("Content-Type") == 'application/json')
                {
                    var response = JSON.parse(this.response);
                    if (response.error && (response.error.type == 'Illuminate\\Session\\TokenMismatchException' || response.error.type == 'Illuminate\Session\TokenMismatchException'))
                    {
                        window.location = window.location;
                    }
                }
            };
            send.apply(this, arguments);
        };
    })();

    setTimezoneCookie();
    dateTimePicker();
    formValidation();
    formButtons();
    bootstrapTabs();
    confirmMessage();
    gridViewInit();
    sideMenu();
    textLocalized();
    select2Control();

    $('[data-toggle="popover"]').popover();
    $('[data-toggle="tooltip"]').tooltip({ container: 'body' });
    $('select[data-gallery="true"]').gallery();
    $('select[data-files-selector="true"]').filesselector();
    /*$('form input[type="text"]:not([data-config]):first').focus();*/

    /*Tooltipy dla zwinietego menu*/
    $('[data-toggle="tooltip-menu"]').tooltip({
        container: 'body',
        placement: 'right',
        title: function()
        {
            if ($(this).find('.sidebar-nav-item').css('display') == 'none')
            {
                return $(this).data('text');
            }
        }
    });

    $(document).on('change', '[data-submit="true"]', function()
    {
        $this = $(this);

        var submit = function()
        {
            $this.parents('form').validate().settings.ignore = "*";
            $this.parents('form').submit();
        };

        if ($this.data('confirm-full'))
        {
            confirmFull(this, 'change', submit);
        }
        else
        {
            submit();
        }
    });

    $(":file").each(function()
    {
        $(this).next().after($(this));
    });

    $(".navbar-fixed-top .toggle").click(function()
    {
        $(this).toggleClass('open');
    });
    
    $('.colorpicker-component').colorpicker({
        colorSelectors: {
            '#676767': '#676767',
            '#000000': '#000000',
            '#ffffff': '#ffffff',
            '#85bc20': '#85bc20',
            '#007ac3': '#007ac3',
            '#e5202e': '#e5202e',
            '#940c72': '#940c72',
            '#241866': '#241866',
            '#ea8f00': '#ea8f00',
            '#474747': '#474747',
            '#009881': '#009881',
            '#cccccc': '#cccccc',
            '#bbbbbb': '#bbbbbb',
        }
    });

    $(document).bind('ajaxSend', function(event, xhr, settings)
    {
        NProgress.start();
    });
    $(document).bind('ajaxSuccess', function(event, xhr, settings)
    {
        $(document).trigger("datetimepicker");
    });
    $(document).bind('ajaxComplete', function(event, xhr, settings)
    {
        NProgress.done();
    });
    $(window).bind('beforeunload', function()
    {
        NProgress.start();
    });

    NProgress.done(true);
});

function baseurl()
{
    return $('meta[name="base-url"]').attr('content');
}

function url(url)
{
    return window.baseurl() + url.replace('admin/', '/').replace('//', '/');
}

function gridViewCheckedItems()
{
    var checked = [];
    $('.gridview tbody input[data-grid-action="checkbox"]:checked').each(function()
    {
        checked.push($(this).val());
    });
    return checked;
}

function gridViewUncheckAll()
{
    $('.gridview input[data-grid-action="checkbox"]:checked').prop( "checked", false );
    $('.batch-action').removeClass('batch-action-active');
}

function gridViewInit()
{
    $('.gridview select[name="pagesize"]').change(function()
    {
        location.href = location.pathname + '?pagesize=' + this.value;
    });

    $('.gridview thead input[data-grid-action="checkbox"]').change(function()
    {
        $('.gridview tbody input[data-grid-action="checkbox"]').prop('checked', $(this).prop('checked'));
    });
    
    $('.gridview tbody input[data-grid-action="checkbox"], .gridview thead input[data-grid-action="checkbox"]').change(function()
    {
        var checked = $('.gridview tbody input[data-grid-action="checkbox"]:checked').length;
        if (checked == 0)
        {
            $('.gridview thead input[data-grid-action="checkbox"]').prop('checked', false);
            $('.batch-action').removeClass('batch-action-active');
        }
        else
        {
            $('.batch-action').addClass('batch-action-active');
        }
    });

    $(document).on('click', '.gridview tr', function()
    {
        $(this).parents('tbody').find('tr').removeClass('active');
        $(this).addClass('active');
    });
}

function confirmFull(element, eventName, callback)
{
    var $this = $(element);

    if (!$this.data('confirm-result'))
    {
        BootstrapDialog.show({
            title: __('Potwierdzenie'),
            message: $this.data('confirm-full'),
            buttons: [{
                label: __('Tak'),
                cssClass: 'btn-primary',
                action: function (dialog)
                {
                    dialog.close();
                    $this.data('confirm-result', true);
                    $this.trigger(eventName);
                }
            }, {
                label: __('Nie'),
                action: function (dialog)
                {
                    dialog.close();
                }
            }]
        });

        return false;
    }
    else
    {
        callback($this);
    }
}

function confirmMessage()
{
    $('[data-confirm]')
        .popover({
            html: true,
            container: 'body',
            placement: 'left',
            animation: false,
            template: '<div class="popover" data-toggle="tooltip"><div class="arrow"></div><div class="popover-content"></div></div>',
            content: function ()
            {
                var $this = $(this);
                var content = $('<div class="confirm"></div>');
                var buttons = $('<div class="btn-group"></div>');
                var eventName = $this.data('confirm-event');
                var activeButtons = {
                    "ok": function ()
                    {
                        if(eventName)
                        {
                            $this.popover('hide');
                            $this.trigger(eventName);
                            return false;
                        }
                        else
                        {
                            $.ajax({type: 'POST', url: $this.attr('href'), data: {confirm: true}})
                                .done(function (result)
                                {
                                    location.reload(true);
                                });
                        }

                        $this.popover('hide');
                    },
                    "remove": function ()
                    {
                        $this.popover('hide');
                    }
                };

                for (var name in activeButtons)
                {
                    var btn = $('<a href="#" class="btn btn-default btn-sm"></a>');
                    btn.append('<i class="glyphicon glyphicon-' + name + '"></i>').click(activeButtons[name]);
                    buttons.append(btn);
                }

                buttons.find('.btn:first').addClass('btn-primary');

                content.append('<div class="txt">' + $this.data('confirm') + '</div>');
                content.append(buttons);

                return content;
            }
        })
        .click(function ()
        {
            return false;
        });

    $(document).on('click', '[data-confirm-full]:not([data-submit])', function ()
    {
        return confirmFull(this, 'click', function (element)
        {
            if (element.data('confirm-ajax'))
            {
                $.ajax({type: 'POST', url: element.attr('href'), data: {confirm: true}})
                    .done(function (result)
                    {
                        location.reload(true);
                    });
            }
            else
            {
                element.get(0).click();
            }
        });
    });
}

function formValidation()
{
    if ($.validator)
    {
        $('form').each(function ()
        {
            $(this).validate({
                errorElement: 'span',
                errorClass: 'text-danger',
                highlight: function (element)
                {
                    $(element).closest('.control-group').addClass('error');
                    $(element).closest('.form-group').addClass('has-error');
                },
                unhighlight: function (element)
                {
                    $(element).closest('.control-group').removeClass('error');
                    $(element).closest('.form-group').removeClass('has-error');
                },
                errorPlacement: function (error, element)
                {
                    var group = element.parent('.input-group');

                    if (group.length > 0)
                        element = group.first();

                    error.insertAfter(element);
                },
                submitHandler: function(form)
                {
                    form.submit();
                }
            });
        });
    }
}

var formButtons = function ()
{
    $('button[data-form], input[data-form]').click(function ()
    {
        $that = $(this);

        if (this.form == null && $that.data('form'))
        {
            $form = $($that.data('form'));
            $input = $('<input type="hidden">').attr('name', $that.attr('name')).val($that.val());
            $form.append($input).submit();

            return false;
        }
    });
};

function setTimezoneCookie()
{
    var today = new Date();
    var currentOffset = (-1 * today.getTimezoneOffset()) + '|' + today.dst();
    var timezoneCookie = "TimeZoneOffset";

    /*if the timezone cookie not exists create one.*/
    if (!$.cookie(timezoneCookie))
    {
        /*check if the browser supports cookie*/
        var cookieSupport = 'CookieSupport';
        $.cookie(cookieSupport, true, { path: '/' });

        /*browser supports cookie*/
        if ($.cookie(cookieSupport))
        {
            /*delete the test cookie*/
            $.removeCookie(cookieSupport, { path: '/' });

            /*create a new cookie*/
            $.cookie(timezoneCookie, currentOffset, { path: '/' });

            /*reload the page*/
            location.reload();
        }
    }
    /*if the current timezone and the one stored in cookie are different
    then store the new timezone in the cookie and refresh the page.*/
    else
    {
        var storedOffset = $.cookie(timezoneCookie);

        /*user may have changed the timezone*/
        if (storedOffset !== currentOffset)
        {
            $.cookie(timezoneCookie, currentOffset, { path: '/' });
            location.reload();
        }
    }
}

function bootstrapTabs()
{
    var tabs = $('a[data-toggle="tab"]');

    if (tabs.length > 0)
    {
        tabs.on('shown.bs.tab', function (e)
        {
            localStorage.setItem('tabs.current', $(e.target).attr('href'));
        });

        var current = localStorage.getItem('tabs.current');

        /*if (current)
        {
            tabs.filter('[href="' + current + '"]').tab('show');
        }*/
    }
    else
    {
        localStorage.removeItem('tabs.current');
    }
}

function sideMenu()
{
    $('#menu').metisMenu();

    $('[data-toggle="offcanvas-wrap"]').click(function ()
    {
        $('.offcanvas-wrap').toggleClass('active');
    });

    $('.offcanvas-wrap .offcanvas-sidebar')
        .mouseenter(function()
        {
            $(this).getNiceScroll().resize().show();
        })
        .mouseleave(function()
        {
            $(this).getNiceScroll().resize().hide();
        })
        .niceScroll(niceScrollOptions);

    $('[data-toggle="quick-sidebar"]').click(function ()
    {
        $('.quick-sidebar').toggleClass('active');
    });
}

function dateTimePicker()
{
    var config = {};

    config.tooltips = {
        today: 'Dzisiaj',
        clear: 'Wyczyść',
        close: 'Zamknij',
        selectMonth: 'Wybierz miesiąc',
        prevMonth: 'Poprzedni miesiąc',
        nextMonth: 'Następny miesiąc',
        selectYear: 'Wybierz rok',
        prevYear: 'Poprzedni rok',
        nextYear: 'Następny rok',
        selectDecade: 'Wybierz dekadę',
        prevDecade: 'Poprzednia dekada',
        nextDecade: 'Następna dekada',
        prevCentury: 'Poprzedni wiek',
        nextCentury: 'Następny wiek',
        pickHour: 'Kalendarz',
        incrementHour: '+1 godzina',
        decrementHour: '-1 godzina',
        pickMinute: 'Minuta',
        incrementMinute: '+1 minuta',
        decrementMinute: '-1 minuta',
        pickSecond: 'sekunda',
        incrementSecond: '+1 sekunda',
        decrementSecond: '-1 sekunda',
        togglePeriod: 'Zmień zakres',
        selectTime: 'Godzina'
    };

    $(document).on('datetimepicker', function ()
    {
        $('input[data-toggle="datetimepicker"]').each(function ()
        {
            $.extend(config, $(this).data('config'));

            $(this).datetimepicker(config);
        })
        .next('.input-group-addon').css('cursor', 'pointer').click(function ()
        {
            $(this).prev().focus();
        });
    });

    $(document).trigger("datetimepicker");

    $('input[data-toggle="datetimerange"]').each(function ()
    {
        var $this = $(this);
        var value = $this.val();

        $.extend(config, $(this).data('config'));

        $this.daterangepicker(config)
            .val(value)
            .on('cancel.daterangepicker', function(ev, picker)
            {
                $(this).val('');
            })
            .next('.input-group-addon').css('cursor', 'pointer').click(function ()
            {
                $(this).prev().focus();
            });
    });
}

function select2Control()
{
    var format = function (format) {
        return format.text || format.name;
    };
    $('select[data-select-2]').each(function () 
    {
        var multiple = !!$(this).attr('multiple');
        var url = $(this).attr('data-select-2-action');
        var config = {
            multiple: multiple,
        };
        if (url)
        {
            config['minimumInputLength'] = 1;
            config['ajax'] = {
                url: url,
                type: "post",
                dataType: 'json',
                delay: 250,
                data: function (params) {
                    return {
                        query: params.term,
                        page: params.page || 1,
                        size: 25
                    };
                },
                processResults: function (data, params) {
                    params.page = params.page || 1;
                    return {
                        results: data.items,
                        pagination: {
                            more: (params.page * 10) < data.totalItems
                        }
                    };
                },
                cache: true
            };
        }
        $(this).select2(config);
    });
}

function textLocalized()
{
    $(document).on('click', 'div[data-toggle="translate-selector"] button', function ()
    {
	$('div[data-toggle="translate-selector"] button').removeClass('active');
	$(this).addClass('active');
        var locale = $(this).attr('data-locale');

        $('div[data-toggle="textlocalized"]').each(function(){
            /*find input and show it. hide all other inputs*/
            $(this).find('input').hide();
            $(this).find('input[data-locale="'+locale+'"]').show();
            /*textarea*/
            $(this).find('textarea').hide();
            $(this).find('textarea[data-locale="'+locale+'"]').show();
           /*info*/
           $(this).find('span[data-toggle="locale-info"]').html(locale.toUpperCase());
        });

        if(window.tinymce)
        {
            $('textarea[data-editor-current="true"]').each(function(i, e){
                tinymce.remove('#' + $(e).attr('id'));
                $(e).attr('data-editor-current', 'false').css('display', 'none');
                $('#' +$(e).data('editor-group') + '_' + locale).css('display', 'block').attr('data-editor-current', 'true');
            });

            tinymceCustom.init('[data-editor-current="true"]');
        }
    });
}

function colorpicker()
{
    $('.colorpicker').colorpicker();
}

if (!String.prototype.format)
{
    String.prototype.format = function ()
    {
        var args = arguments;
        return this.replace(/{(\d+)}/g, function (match, number)
        {
            return typeof args[number] != 'undefined' ? args[number] : match;
        });
    };
}

if (!Date.prototype.stdTimezoneOffset)
{
    Date.prototype.stdTimezoneOffset = function ()
    {
        var jan = new Date(this.getFullYear(), 0, 1);
        var jul = new Date(this.getFullYear(), 6, 1);
        return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
    };
}

if (!Date.prototype.dst)
{
    Date.prototype.dst = function ()
    {
        return this.getTimezoneOffset() < this.stdTimezoneOffset();
    };
}

jQuery.fn.slug = function(options)
{
    var settings = {
        auto: true, /*Automatyczne generowanie sluga podczas pisania*/
        slug: 'input.slug', /*Selector inputa zawierajacego slug*/
        btn: 'a.clearslug' /*Selector przycisku generujacego slug recznie*/
    };

    if (options)
    {
        jQuery.extend(settings, options);
    }

    var $this = jQuery(this);
    var $dest = jQuery(settings.slug);
    var $btn = jQuery(settings.btn);

    var search = [" ", "ę", "ó", "ą", "ś", "ł", "ż", "ź", "ć", "ń"];
    var replace = ["-", "e", "o", "a", "s", "l", "z", "z", "c", "n"];

    var makeSlug = function(text)
    {
        text = text.toLowerCase();

        var re = /a/;

        for(var i = 0; i < search.length; i++)
        {
            re.compile(search[i], "g");
            text = text.replace(re, replace[i]);
        }

        text = text.replace(/[^a-z0-9-]/g, "");

        if (jQuery.isNumeric($dest.data("ruleMaxlength")))
            text = text.substr(0, $dest.data("ruleMaxlength"));

        return text;
    };

    $this.keyup(function ()
    {
        if (settings.auto)
        {
            var text = makeSlug($this.val());
            var length = Math.min($dest.val().length, text.length);

            if (text.substring(0, length) == $dest.val().substring(0, length))
            {
                $dest.val(text);
            }
        }
    });

    $dest.keydown(function (e)
    {
        if ($.inArray(e.key, search) > -1)
        {
            return false;
        }
    });

    $btn.click(function ()
    {
        $dest.val(makeSlug($this.val()));
    });

    return $this;
};

var tinymceCustom = {
    onInitCallback: true,
    plugins: [
        'advlist autolink lists link image gallery filemanager charmap hr anchor autoresize',
        'searchreplace wordcount visualblocks visualchars code',
        'insertdatetime media nonbreaking save table contextmenu directionality',
        'emoticons paste textcolor colorpicker textpattern',
        'autosave localautosave floatingtoolbar linktools tabletools template'
    ],
    selector: 'textarea[data-editor]',

    init: function(selector)
    {
        if(typeof selector != 'undefined' && $.trim(selector) != '')
            this.selector = selector;
        else
            this.selector = 'textarea[data-editor]';

        if (!window.tinymce) return;

        tinymce.init({
            selector:this.selector,
            plugins: this.plugins,
            toolbar1: [
                'undo', 'redo', '|', 'bold', 'italic', 'underline', 'strikethrough', '|', 'alignleft', 'aligncenter', 'alignright', 'alignjustify', '|', 'outdent', 'indent', '|', 
                'bullist', 'numlist', '|', 'forecolor', 'backcolor', 'removeformat', '|', 'tabletools_insert', 'link', 'image', '|', 'code', 'localautosave', '|', ' template'
            ].join(' '),
            templates:  "/themes/admin/bundle/js/tiny-mce-template.js",
            content_css: "/themes/admin/bundle/css/tiny-mce-styles.css",
            relative_urls: false,
            paste_data_images: false,
            browser_spellcheck: true,
            entity_encoding : "raw",
            autoresize_bottom_margin: 50,
            nowrap : false,
            resize: false,
            language : $('html').attr('lang'),
            floatingtoolbar_top: 51,
            linktools_toolbar: 'link | unlink',
            las_seconds: 15,
            table_style_by_css: true,
            visualblocks_default_state: true,
            extended_valid_elements : "edito[class|data*],div[class|data*]",
            custom_elements: "edito",

            file_browser_callback: function(field, url, type, win)
            {
                var files = [];

                if (url.length > 0 && url.indexOf('file-id:') == 0)
                {
                    files.push(parseInt(url.replace('file-id:', '')));
                }

                FileManager.showModal(type, files, null, function(items)
                {
                    var file = '';
                    var input = $('#' + field);

                    if (items.length > 0)
                    {
                        file = 'file-id:' + items[0];

                        var row = input.parents('.mce-container:first');
                        var textbox = row.next().find('.mce-textbox');
                        var size = row.next().next().find('.mce-textbox');

                        if (textbox.next('.mce-btn').length == 0)
                        {
                            $.ajax({
                                type: 'POST',
                                url: '/admin/filemanager/fileinfo',
                                data: {'id': items[0]},
                                dataType: 'json',
                                success: function (data)
                                {
                                    if (textbox.val().length == 0)
                                    {
                                        textbox.val(data.name + ' [' + data.content_length + ']');
                                    }
                                    if (size.length == 2)
                                    {
                                        var dim = data.size.split('x');
                                        size.eq(0).val(dim[0]);
                                        size.eq(1).val('');
                                    }
                                }
                            });
                        }
                    }

                    input.val(file);
                });
            },

            setup: function (ed) {
                ed.on('init', function (e) {
                    if (tinymceCustom.onInitCallback && typeof(tinymceOnInit) === 'function') {
                        tinymceOnInit();
                    }
                });
                ed.on('BeforeSetContent', function(e)
                {
                    if (e.content)
                    {
                        var html = $('<div></div>').html(e.content);

                        $('img', html).each(function()
                        {
                            var $this = $(this);
                            var src = $this.attr('src');

                            if (src.indexOf('file-id:') == 0)
                            {
                                $this.attr('data-src', src);
                                $this.attr('src', '/admin/tinymce/imagepreview/' + src.replace('file-id:', '') + '?view');
                            }
                        });

                        e.content = html.html();
                    }
                });
                ed.on('NodeChange', function(e)
                {
                    if (e.element && e.element.nodeName == 'IMG')
                    {
			            $img = $(e.element);
			            var src = $img.attr('src');

                        if (src.indexOf('file-id:') == 0)
                        {
                            $img.attr('data-src', src);
                            $img.attr('src', '/admin/tinymce/imagepreview/' + src.replace('file-id:', '') + '?view');
                        }
                    }
                });
                ed.on('PostProcess', function(e)
                {
                    if (e.get)
                    {
                        /*Zablokowanie doczytywania obrazkow przez parser jquery*/
                        e.content = e.content.replace(new RegExp('src', 'g'), 'src-disabled');

                        var html = $('<div></div>').html(e.content);

                        $('img', html).each(function()
                        {
                            var $this = $(this);
                            var src = $this.attr('data-src-disabled');

                            if (src && src.indexOf('file-id:') == 0)
                            {
                                $this.attr('src-disabled', src);
                                $this.removeAttr('data-src-disabled');
                            }
                        });

                        e.content = html.html();

                        /*Przywrocenie oryginalnego kodu html*/
                        e.content = e.content.replace(new RegExp('src-disabled', 'g'), 'src');
                    }
                });
            }
        });
    }
};

/* TinyMCE */
$(function ()
{
    tinymceCustom.init();
    tinymceCustom.init('textarea[data-editor-current="true"]');
});

/* Lokalizacja */
var i18n = (function ()
{
    var lang = null;
    var domain = null;
    var endpoint = null;
    var translation = null;

    var getContainer = function(domain, lang)
    {
        return 'i18n.' + domain + '.' + lang;
    };

    var setStorage = function (key, content, expires)
    {
        if (!expires) expires = 24 * 3600;

        var date = new Date();
        var schedule = Math.round((date.setSeconds(date.getSeconds() + expires)) / 1000);

        localStorage.setItem(key, content);
        localStorage.setItem(key + '.expires', schedule);
    };

    var getStorage = function(key)
    {
        var date = new Date();
        var current = Math.round(date / 1000);
        var expires = localStorage.getItem(key + '.expires');

        if (!expires) expires = 0;

        if (expires < current)
        {
            localStorage.removeItem(key);
            localStorage.removeItem(key + '.expires');
        }

        return localStorage.getItem(key);
    };

    var loadTranslation = function(domain, lang)
    {
        var result = [];

        $.ajax(endpoint, { async: false })
            .done(function(data, status, xhr)
            {
                var key = getContainer(domain, lang);

                setStorage(key, xhr.responseText);

                result = data;
            })
            .fail(function()
            {
                result = [];
            });

        return result;
    };

    var getTranslation = function (domain, lang)
    {
        if (!translation)
        {
            var i18n = getStorage(getContainer(domain, lang));

            if (i18n)
            {
                i18n = JSON.parse(i18n);
            }
            else
            {
                i18n = loadTranslation(domain, lang);
            }

            translation = i18n;
        }

        return translation;
    };

    var init = function()
    {
        if (!lang && !domain)
        {
            var html = $('html > head');

            lang = html.find('meta[name="i18n.locale"]').attr('content') || 'pl';
            domain = html.find('[name="i18n.domain"]').attr('content') || 'messages';
            endpoint = '/i18n.' + domain + '.' + lang + '.js';
        }
    };

    return {
        getText : function (key)
        {
            init();

            var i18n = getTranslation(domain, lang);

            return i18n[key] ? i18n[key] : key;
        }
    };
})();

var __ = function(key)
{
    return i18n.getText(key);
};

/* Zarzadzanie blokami */
var WidgetsManager = (function()
{
    var self = {};
    var wnd = null;
    var doc = null;
    var endpoints = {};
    var canViewWidget = false;
    var canCreateWidget = false;
    var events = [
        "click",
        "dbclick",
        "mousedown",
        "mouseup",
        "mousemove",
        "mouseover",
        "mouseout",
        "mouseenter",
        "mouseleave"
    ];

    self.eventListener = function(e)
    {
        e.stopPropagation();
        e.preventDefault();

        if (e.target)
        {
            e.target.style.cursor = 'default';

            if ($(e.target).hasClass('admin-widget-button') || $(e.target).parent().hasClass('admin-widget-button'))
            {
                e.target.style.cursor = 'pointer';
            }

            if (e.type == "click")
            {
                self.handleTarget($(e.target));
            }
        }
    };

    self.replaceSections = function(data)
    {
        var sections = $(doc).find('.admin-section[data-section]');

        sections.each(function()
        {
            var section = $(this).data('section');
            var selector = '.admin-section[data-section="' + section + '"]';
            var replacement = $(data).find(selector);

            $(this).replaceWith(replacement);
        });
    };

    self.handleTarget = function(target)
    {
        var button = target.parents('.admin-widget-button');
        var container = target.parents('.admin-widget, .admin-section').first();

        if (container.length == 0 && (target.hasClass('admin-section') || target.hasClass('admin-widget')))
        {
            container = target;
        }

        if (container.length > 0)
        {
            $('.admin-selected', doc).removeClass('admin-selected');

            container.addClass('admin-selected');

            var editButton = container.find('.admin-widget-button[data-action="edit"]');

            if (editButton.length > 0)
            {
                if (canViewWidget)
                    editButton.show();
                else
                    editButton.hide();
            }

            if (button.length > 0 && button.data('action'))
            {
                self.handleAction(button.data('action'), container.data());
            }
        }
    };

    self.handleAction = function(action, data)
    {
        switch (action)
        {
            case 'add':
                self.handleAddAction(data);
                break;
            case 'edit':
                self.handleEditAction(data);
                break;
            case 'remove':
                self.handleRemoveAction(data);
                break;
            case 'move':
                self.handleMoveAction(data);
                break;
            case 'up':
                self.handleUpAction(data);
                break;
            case 'down':
                self.handleDownAction(data);
                break;
        }
    };

    self.handleAddAction = function(params)
    {
        BootstrapDialog.show({
            title: __('Wybierz blok'),
            spinicon: 'fa fa-spinner',
            message: function(dialog)
            {
                return $('<div></div>').load(endpoints.widgets, params);
            },
            buttons: [
                {label: __('Dodaj nowy blok'), id: 'btnCreateWidget', cssClass: 'btn-success pull-left', action: function(dialog)
                {
                    location.href = endpoints.add + '/' + params.website + '/' + params.locale;
                }},
                {label: __('Wybierz'), cssClass: 'btn-primary', action: function(dialog)
                {
                    var active = $('#widget-list').find('.active');

                    if (active.length > 0)
                    {
                        $.post(endpoints.set + '/' + active.data('widget'), params, function(data)
                        {
                            self.replaceSections(data);

                            dialog.close();
                        });

                        this.disable();
                        this.spin();
                    }
                }},
                {label: __('Zamknij'), action: function(dialog)
                {
                    dialog.close();
                }}
            ],
            onshow: function(dialog)
            {
                if (canCreateWidget == false)
                {
                    dialog.getButton('btnCreateWidget').hide();
                }
            }
        });
    };

    self.handleEditAction = function(params)
    {
        location.href = endpoints.edit + '/' + params.widget;
    };

    self.handleRemoveAction = function(params)
    {
        $.post(endpoints.remove + '/' + params.widget, params, function(data)
        {
            self.replaceSections(data);
        });
    };

    self.handleMoveAction = function(params)
    {
        BootstrapDialog.show({
            title: __('Przenieś blok'),
            spinicon: 'fa fa-spinner',
            message: function(dialog)
            {
                return $('<div></div>').load(endpoints.sections, params);
            },
            buttons: [
                {label: __('Przenieś'), cssClass: 'btn-primary', action: function(dialog)
                {
                    var section = $('#widget-section');

                    if (section.length > 0)
                    {
                        $.post(endpoints.move + '/' + section.val(), params, function(data)
                        {
                            self.replaceSections(data);

                            $(doc).find('.admin-widget[data-widget="' + params.widget + '"]').addClass('admin-selected');

                            dialog.close();
                        });

                        this.disable();
                        this.spin();
                    }
                }},
                {label: __('Zamknij'), action: function(dialog)
                {
                    dialog.close();
                }}
            ]
        });
    };

    self.handleUpAction = function(params)
    {
        $.post(endpoints.sort + '/up/' + params.widget, params, function(data)
        {
            self.replaceSections(data);

            $(doc).find('.admin-widget[data-widget="' + params.widget + '"]').addClass('admin-selected');
        });
    };

    self.handleDownAction = function(params)
    {
        $.post(endpoints.sort + '/down/' + params.widget, params, function(data)
        {
            self.replaceSections(data);

            $(doc).find('.admin-widget[data-widget="' + params.widget + '"]').addClass('admin-selected');
        });
    };

    return {
        run : function(iframe, options, canView, canCreate)
        {
            wnd = iframe.contentWindow;
            doc = iframe.contentWindow.document;
            endpoints = options;
            canViewWidget = canView;
            canCreateWidget = canCreate;

            events.forEach(function (event)
            {
                doc.addEventListener(event, self.eventListener, true);
            });

            setInterval(function()
            {
                if ($(iframe).attr('height') == '100%')
                {
                    iframe.style.height = Math.max($(doc).height(), 480) + 'px';
                }
            },
            50);
        }
    };
})();

var FileManager = (function()
{
    return {
        type: null,
        files: [],
        dialog: null,
        onSave: null,
        defaultFolder: null,

        showModal: function(type, files, defaultFolder, saveCallback)
        {
            $this = this;

            this.type = type;
            this.files = files;
            this.onSave = saveCallback;
            this.defaultFolder = defaultFolder;

            this.dialog = BootstrapDialog.show(
                {
                    cssClass: 'filemanager-dialog',
                    title: 'File Manager',
                    message: function(dialog)
                    {
                        return $('<div></div>').load('/admin/filemanager?media=' + $this.type + (($this.defaultFolder != null) ? '&folder=' + $this.defaultFolder : ''));
                    },
                    onshow: function(dialog)
                    {
                        dialog.getModalHeader().hide();
                    }
                });
        },

        load: function (files, size, layout, type, callback, showDel)
        {
            showDel = typeof showDel !== 'undefined' ? true : false;

            $.ajax({
                type: "POST",
                url: '/admin/filemanager/gallery',
                data: {files: files, size: size, layout: layout, type: type, showDel: showDel},
                success: callback
            });
        },

        getFiles: function ()
        {
            return this.files;
        },

        saveFiles: function (files)
        {
            this.onSave(files);
            this.type = null;
            this.files = [];
            this.onSave = null;
            this.defaultFolder = null;
            this.closeModal();
        },

        closeModal: function ()
        {
            if (this.dialog)
            {
                this.dialog.close();
            }
        }
    };
})();

/* Kontrolka galerii zdjec */
(function ($)
{
    var methods = {

        init: function(options)
        {
            return this.each(function ()
            {
                var $this = $(this);
                var files = [];
                var position;

                $this.find('option').each(function()
                {
                    files.push($(this).val());
                });

                var container = $('<div class="images-container"></div>')
                    .insertAfter($this)
                    .data('files', files)
                    .click(function (event)
                    {
                        if ($(event.target).hasClass('item-del'))
                        {
                            files = container.data('files');
                            position = $.inArray($(event.target).parent().parent().data('id'), files);
                            if (~position) files.splice(position, 1);
                            container.data('files', files);

                            methods.load.apply($this, [container, files]);
                            return false;
                        }

                        methods.showPicker.apply($this, [container]);
                    });

                methods.load.apply($this, [container, files]);
            });
        },

        showPicker: function(container)
        {
            var $this = this;

            FileManager.showModal('gallery', container.data('files'), $this.data('default-folder'), function(files)
            {
                methods.load.apply($this, [container, files]);
            });
        },

        load: function(container, files)
        {
            var $this = this;

            if (!$this.prop('multiple'))
                files = files.splice(0,1);

            FileManager.load(files, 'small', 'horizontal', 'gallery', function (data)
            {
                var html = $(data);

                var files = html.find('[data-id]').map(function ()
                {
                    return $(this).data('id');
                })
                    .toArray();

                $this.find('option').remove();

                $(files).each(function(i, value)
                {
                    $this.append($("<option></option>").attr('value', value).text(value).attr('selected', 'selected'));
                });

                container.html(html).data('files', files);
            }, true);

            if (files.length == 0)
                $this.before('<input type="hidden" value="" name="'+$this.attr('name')+'" />');

        }
    };

    $.fn.gallery = function(methodOrOptions)
    {
        if (methods[methodOrOptions])
        {
            return methods[methodOrOptions].apply(this, Array.prototype.slice.call(arguments, 1));
        }
        else if (typeof methodOrOptions === 'object' || ! methodOrOptions)
        {
            /*Default to "init"*/
            return methods.init.apply(this, arguments);
        }
        else
        {
            $.error('Method ' +  methodOrOptions + ' does not exist on jQuery.gallery');
        }
    };
}(jQuery));

/* Kontrolka wyboru plikow */
(function ($)
{
    var methods = {

        init: function(options)
        {
            return this.each(function ()
            {
                var $this = $(this);
                var files = [];
                var position;
                var type = $this.data('file-types');

                $this.find('option').each(function()
                {
                    files.push($(this).val());
                });

                var container = $('<div class="images-container"></div>')
                    .insertAfter($this)
                    .data('files', files)
                    .data('type', type)
                    .click(function (event)
                    {
                        if ($(event.target).hasClass('item-del'))
                        {
                            files = container.data('files');
                            position = $.inArray($(event.target).parent().parent().data('id'), files);
                            if (~position) files.splice(position, 1);
                            container.data('files', files);

                            methods.load.apply($this, [container, files]);
                            return false;
                        }

                        methods.showPicker.apply($this, [container]);
                    });

                methods.load.apply($this, [container, files]);
            });
        },

        showPicker: function(container)
        {
            var $this = this;

            FileManager.showModal(container.data('type'), container.data('files'), $this.data('default-folder'), function(files)
            {
                methods.load.apply($this, [container, files]);
            });
        },

        load: function(container, files)
        {
            var $this = this;

            if (!$this.prop('multiple'))
                files = files.splice(0,1);

            FileManager.load(files, 'small', 'horizontal', 'gallery', function (data)
            {
                var html = $(data);

                var files = html.find('[data-id]').map(function ()
                {
                    return $(this).data('id');
                })
                    .toArray();

                $this.find('option').remove();

                $(files).each(function(i, value)
                {
                    $this.append($("<option></option>").attr('value', value).text(value).attr('selected', 'selected'));
                });

                container.html(html).data('files', files);
            }, true);

            if (files.length == 0)
                $this.before('<input type="hidden" value="" name="'+$this.attr('name')+'" />');

        }
    };

    $.fn.filesselector = function(methodOrOptions)
    {
        if (methods[methodOrOptions])
        {
            return methods[methodOrOptions].apply(this, Array.prototype.slice.call(arguments, 1));
        }
        else if (typeof methodOrOptions === 'object' || ! methodOrOptions)
        {
            /*Default to "init"*/
            return methods.init.apply(this, arguments);
        }
        else
        {
            $.error('Method ' +  methodOrOptions + ' does not exist on jQuery.gallery');
        }
    };
}(jQuery));

$(function()
{
    $(window).scroll(function()
    {
        var scrollTop = $(window).scrollTop();
        var windowWidth = $(window).width();
        var pageHeight = $(document).height();

        $('[data-spy="sticky"]').each(function()
        {
            var sticky = $(this);
            var top = parseInt(sticky.data('offset-top'));
            var bottom = parseInt(sticky.data('offset-bottom'));
            var minWidth = parseInt(sticky.data('min-width'));
            var height = sticky.outerHeight();
            var parentTop = sticky.parent().offset().top;
            var margin = 0;

            if (windowWidth >= minWidth)
            {
                if ((scrollTop - parentTop + top) > 0)
                {
                    margin = scrollTop - parentTop + top;
                }
                if (pageHeight - bottom - height - parentTop - margin <= 0)
                {
                    margin = pageHeight - bottom - height - parentTop;
                }
                if (margin < 0)
                {
                    margin = 0;
                }
            }

            sticky.stop().animate({ marginTop: parseInt(margin) }, 500);
            /*sticky.css('margin-top', parseInt(margin));*/
        });
    });
});

var Helpdesk = {
	response: null,

	init: function(){
		$('[data-toggle="helpdesk-edit"]').click(function ()
		{
			$('#helpdesk-edit-form').submit();
		});

		$('[data-toggle="helpdesk-info"]').click(function ()
		{
			if(Helpdesk.response == null)
			{
				var originId = $(this).data('origin-id');
				var url = $(this).data('ajax-url');
				var actionName = $(this).data('action-name');
				if(parseInt(originId) > 0)
				{
					$.ajax({
						type: 'POST',
						url: url,
						data: {
							id: originId,
							action_name: actionName
						},
						dataType: 'json',
						success: function (response)
						{
							if(response.status == 1)
							{
								Helpdesk.response = {'title': response.title, 'content': response.content};
								Helpdesk.showModal(Helpdesk.response);
							}
						}
					});
				}
			}
			else
			{
				Helpdesk.showModal(Helpdesk.response);
			}
		});
	},

	showModal: function(response){
		BootstrapDialog.show({
			title: response.title,
			message: response.content,
			size: BootstrapDialog.SIZE_WIDE,
			buttons: [{
				label: __('OK'),
				cssClass: 'btn-primary',
				action: function (dialog)
				{
					dialog.close();
				}
			}]
		});
	}
};

$(function(){
	Helpdesk.init();
});


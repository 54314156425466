angular
    .module('edito.formview.multiselect', ['ui.bootstrap'])
    .config(['$httpProvider', function($httpProvider)
    {
        $httpProvider.defaults.headers.common["X-Requested-With"] = 'XMLHttpRequest';
    }])
    .constant('multiselectConfig',
    {
        selectedTabText: 'Selected',
        searchTabText: 'Search',
        searchText: 'Type to search...',
        emptyText: 'No results found.'
    })
    .directive('ngEditoMultiselectSelected', [function()
    {
        return {
            restrict: 'A',
            require: ['ngEditoMultiselectSelected', '^ngEditoMultiselect'],
            scope: {
                'isChecked': '&',
                'change': '&'
            },
            controller: function($scope, $http)
            {
                $scope.items = [];
                
                $scope.totalItems = 0;
                $scope.itemsPerPage = 10;
                $scope.currentPage = 1;
                $scope.page = [];
                
                $scope.$watchGroup(['$parent.selected.length'], function ()
                {
                    if ($scope.$parent.selected.length > 0)
                    {
                        $http
                            .post($scope.$parent.url,
                            {
                                selected: $scope.$parent.selected
                            })
                            .success(function (data)
                            {
                                $scope.totalItems = data.totalItems;
                                $scope.items = data.items;
                            })
                            .error(function (data)
                            {
                                $scope.items = [];
                            });
                    }
                    else
                    {
                        $scope.items = [];
                    }
                });

                $scope.$watchGroup(['currentPage', 'itemsPerPage', 'totalItems'], function ()
                {
                    var start = ($scope.currentPage - 1) * $scope.itemsPerPage;
                    $scope.page = $scope.items.slice(0).splice(start, $scope.itemsPerPage);
                });

                $scope.isChecked = function(item)
                {
                    return $scope.$parent.isChecked(item);
                };
                
                $scope.change = function(item)
                {
                    $scope.$parent.change(item);
                };
            },
            template: '' +
            '<select multiple name="{{$parent.name}}[]" ng-show="false">' +
                '<option ng-repeat="id in $parent.selected" value="{{id}}" selected>{{id}}</option>' +
            '</select>' +
            '<table class="table table-condensed">' +
                '<colgroup>' +
                    '<col width="30">' +
                    '<col>' +
                '</colgroup>' +
                '<tbody ng-show="items.length > 0">' +
                    '<tr ng-repeat="item in page">' +
                        '<td>' +
                            '<input type="checkbox" data-id="{{item.id}}" ng-checked="isChecked(item)" ng-click="change(item)">' +
                        '</td>' +
                        '<td>' +
                            '{{item.name}}' +
                        '</td>' +
                    '</tr>' +
                '</tbody>' +
                '<tbody ng-show="items.length == 0">' +
                    '<tr>' +
                        '<td colspan="2">' +
                            '<div class="alert alert-warning text-center">' +
                                '{{$parent.emptyText}}' +
                            '</div>' +
                        '</td>' +
                    '</tr>' +
                '</tbody>' +
                '<tfoot>' +
                    '<tr ng-show="totalItems > page.length">' +
                        '<td colspan="2" class="text-center">' +
                            '<pagination total-items="totalItems" items-per-page="itemsPerPage" ng-model="currentPage" previous-text="«" next-text="»"></pagination>' +
                        '</td>' +
                    '</tr>' +
                '</tfoot>' +
            '</table>'
        };
    }])
    .directive('ngEditoMultiselectSearch', [function()
    {
        return {
            restrict: 'A',
            require: ['ngEditoMultiselectSearch', '^ngEditoMultiselect'],
            scope: {
                'isChecked': '&',
                'change': '&'
            },
            controller: function($scope, $http)
            {
                $scope.items = [];
                $scope.search = '';

                $scope.totalItems = 0;
                $scope.itemsPerPage = 10;
                $scope.currentPage = 1;

                $scope.$watchGroup(['search', 'currentPage', 'itemsPerPage'], function ()
                {
                    $http
                        .post($scope.$parent.url,
                        {
                            query: $scope.search,
                            page: $scope.currentPage,
                            size: $scope.itemsPerPage
                        })
                        .success(function (data)
                        {
                            $scope.totalItems = data.totalItems;
                            $scope.items = data.items;
                        })
                        .error(function (data)
                        {
                            $scope.items = [];
                        });
                });

                $scope.isChecked = function(item)
                {
                    return $scope.$parent.isChecked(item);
                };
                
                $scope.change = function(item)
                {
                    $scope.$parent.change(item);
                };
            },
            template: '' +
            '<table class="table table-condensed">' +
                '<colgroup>' +
                    '<col width="30">' +
                    '<col>' +
                '</colgroup>' +
                '<thead>' +
                    '<tr class="active">' +
                        '<td colspan="2">' +
                            '<input type="text" placeholder="{{$parent.searchText}}" class="form-control" ng-model="search" ng-model-options="{debounce: 500}">' +
                        '</td>' +
                    '</tr>' +
                '</thead>' +
                '<tbody ng-show="items.length > 0">' +
                    '<tr ng-repeat="item in items">' +
                        '<td>' +
                            '<input type="checkbox" data-id="@{{ item.id }}" ng-checked="isChecked(item)" ng-click="change(item)">' +
                        '</td>' +
                        '<td>' +
                            '{{ item.name }}' +
                        '</td>' +
                    '</tr>' +
                '</tbody>' +
                '<tbody ng-show="items.length == 0">' +
                    '<tr>' +
                        '<td colspan="2">' +
                            '<div class="alert alert-warning text-center">' +
                                '{{$parent.emptyText}}' +
                            '</div>' +
                        '</td>' +
                    '</tr>' +
                '</tbody>' +
                '<tfoot>' +
                    '<tr ng-show="totalItems > items.length">' +
                        '<td colspan="2" class="text-center">' +
                            '<pagination total-items="totalItems" items-per-page="itemsPerPage" ng-model="currentPage" previous-text="«" next-text="»"></pagination>' +
                        '</td>' +
                    '</tr>' +
                '</tfoot>' +
            '</table>'
        };
    }])
    .directive('ngEditoMultiselect', ['$parse', 'multiselectConfig', function($parse, multiselectConfig)
    {
        return {
            restrict: 'A',
            scope: {
                name: '@',
                url: '@',
                selected: '='
            },
            controller: function($scope)
            {
                $scope.isChecked = function(item)
                {
                    return $scope.selected.indexOf(item.id.toString()) >= 0;
                };

                $scope.change = function(item)
                {
                    var index = $scope.selected.indexOf(item.id.toString());
                    
                    if (index > -1)
                        $scope.selected.splice(index, 1);
                    else
                        $scope.selected.push(item.id.toString());
                };
            },
            template: '' +
            '<tabset justified="true" type="tabs">' +
                '<tab>' +
                    '<tab-heading><i class="fa fa-fw fa-check-square-o"></i> {{selectedTabText}}</tab-heading>' +
                    '<div ng-edito-multiselect-selected name="name" url="url" selected="selected"></div>' +
                '</tab>' +
                '<tab>' +
                    '<tab-heading><i class="fa fa-fw fa-search"></i> {{searchTabText}}</tab-heading>' +
                    '<div ng-edito-multiselect-search name="name" url="url"></div>' +
                '</tab>' +
            '</tabset>',
            link: function(scope, element, attr)
            {
                scope.selectedTabText = angular.isDefined(attr.selectedTabText) ? attr.selectedTabText : multiselectConfig.selectedTabText;
                scope.searchTabText = angular.isDefined(attr.searchTabText) ? attr.searchTabText : multiselectConfig.searchTabText;
                scope.searchText = angular.isDefined(attr.searchText) ? attr.searchText : multiselectConfig.searchText;
                scope.emptyText = angular.isDefined(attr.emptyText) ? attr.emptyText : multiselectConfig.emptyText;
            }
        };
    }]);


$(function()
{
    $('div[ng-edito="multiselect"]').each(function()
    {
        angular.bootstrap(this, ["edito.formview.multiselect"]);
    });
});
